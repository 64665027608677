<template>
    <div>
        <p class="text-h6 mb-4 grey--text">Einstellungen</p>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-tabs v-if="!loading">
            <v-tab>
                <v-icon left> settings </v-icon>
                Allgemein
            </v-tab>
            <v-tab>
                <v-icon left> translate </v-icon>
                Sprachen
            </v-tab>
            <v-tab>
                <v-icon left> code </v-icon>
                Comet
            </v-tab>
            <v-tab>
                <v-icon class="mr-3" left> mail </v-icon>
                Mail
            </v-tab>
            <v-tab>
                <v-icon left> format_list_numbered </v-icon>
                Status
            </v-tab>
            <v-tab>
                <v-icon left> account_balance </v-icon>
                Buchhaltung
            </v-tab>

            <v-tab-item>
                <!-- GENERAL -->
                <v-card flat>
                    <v-card-text>
                        <v-sheet class="pa-5" elevation="0" tile>
                            <p class="headline font-weight-bold mb-5">
                                Allgemein
                            </p>

                            <p class="text-overline mb-0 mt-5">
                                Channel-Thumbnail
                            </p>
                            <v-row class="px-3 mt-3">
                                <div>
                                    <v-img
                                        :src="thumbnailURL"
                                        height="200"
                                        width="300"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0 grey lighten-4"
                                                align="center"
                                                justify="center"
                                            >
                                                Kein Bild gefunden
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>

                                <v-col>
                                    <v-file-input
                                        label="Datei wählen"
                                        style="max-width: 500px"
                                        truncate-length="15"
                                        accept="image/jpeg"
                                        v-model="channelThumbnail"
                                    ></v-file-input>
                                    <v-btn
                                        class="ml-5"
                                        depressed
                                        color="primary"
                                        @click="uploadChannelThumbnail"
                                    >
                                        Hochladen
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <p class="text-overline mb-0 mt-10">
                                Neue Einträge anlegen
                            </p>
                            <v-row class="px-3">
                                <v-col>
                                    <v-select
                                        style="max-width: 400px"
                                        item-value="value"
                                        item-text="description"
                                        v-model="config.general.create.mode"
                                        :items="[
                                            {
                                                description: 'Deaktiviert',
                                                value: 0,
                                            },
                                            {
                                                description: 'Aktiviert',
                                                value: 2,
                                            },
                                            {
                                                description:
                                                    'Aktiviert (Verpflichtende Graph-Verlinkung)',
                                                value: 3,
                                            },
                                        ]"
                                    ></v-select>
                                    <v-autocomplete
                                        v-if="config.general.create.mode > 1"
                                        style="max-width: 800px"
                                        multiple
                                        label="Zusätzliche Channelzuweisung"
                                        hint="Diese Channels werden dem KG-Datensatz automatisch zugewiesen. z.B. für automatische Ausgabe auf Website."
                                        persistent-hint
                                        deletable-chips
                                        chips
                                        item-value="id"
                                        item-text="label"
                                        v-model="
                                            config.general.create.autoChannel
                                        "
                                        :items="processedChannels"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <p class="text-overline mb-0 mt-5">
                                Beschreibung aus IXEDIT in Graph speichern
                            </p>
                            <v-row class="px-3">
                                <v-col>
                                    <v-checkbox
                                        :label="
                                            config.graph.ixedit_desc_sync
                                                .enabled
                                                ? 'aktiviert'
                                                : 'deaktiviert'
                                        "
                                        v-model="
                                            config.graph.ixedit_desc_sync
                                                .enabled
                                        "
                                        hint="Speichert automatisch die Texte des zuletzt mit IXEDIT editierten Eintrags in 'description' des entsprechenden Graph-Datensatzes."
                                        persistent-hint
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row class="px-3">
                                <v-col>
                                    <v-dialog
                                        v-model="resetDialog"
                                        persistent
                                        max-width="600"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                color="error"
                                                depressed
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                Channel zurücksetzen
                                            </v-btn>
                                        </template>
                                        <v-card class="py-2">
                                            <v-card-title class="text-h5">
                                                Channel zurücksetzen
                                            </v-card-title>
                                            <v-card-text>
                                                <p>
                                                    Folgende Aktionen werden
                                                    ausgeführt:
                                                </p>
                                                <ul>
                                                    <li>
                                                        ALLE Templates werden
                                                        GELÖSCHT
                                                    </li>
                                                    <li>
                                                        ALLE Eintragstypen
                                                        werden GELÖSCHT
                                                    </li>
                                                    <li>
                                                        ALLE Einstellungen
                                                        werden GELÖSCHT
                                                    </li>
                                                </ul>
                                                <!--p class="mt-3">Sollen auch die Kataloge und Einträge gelöscht werden?</p-->
                                                <!--v-checkbox v-model="deleteEntries" dense label="JA, ich möchte AUCH alle Auflagen und Einträge löschen"></v-checkbox-->
                                                <p
                                                    class="red--text mt-4"
                                                    v-if="!deleteEntries"
                                                >
                                                    Achtung! Die Einträge werden
                                                    nicht automatisch gelöscht.
                                                    Bitte entweder löschen oder
                                                    die entsprechende
                                                    Datenstruktur wieder
                                                    konfigurieren.
                                                </p>
                                                <p>
                                                    Bitte gib den Namen des
                                                    Channels ein um zu
                                                    bestätigen, dass du die
                                                    Auswirkungen kennst:
                                                </p>
                                                <v-text-field
                                                    persistent-hint
                                                    v-model="
                                                        channelNameConfirmation
                                                    "
                                                    :hint="channelName"
                                                    outlined
                                                ></v-text-field>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn
                                                    color="error"
                                                    text
                                                    large
                                                    @click="resetDialog = false"
                                                >
                                                    Abbrechen
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="error"
                                                    depressed
                                                    large
                                                    :disabled="
                                                        channelName !==
                                                        channelNameConfirmation
                                                    "
                                                    @click="resetPrintSettings"
                                                >
                                                    UNWIDERRUFLICH LÖSCHEN
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <!-- LANGUAGE -->
                <v-card flat>
                    <v-card-text>
                        <v-sheet class="pa-5" elevation="0" tile>
                            <p class="headline font-weight-bold">Sprachen</p>

                            <p class="text-overline mb-0 mt-3">
                                Verfügbare Sprachen
                            </p>
                            <v-autocomplete
                                style="max-width: 500px"
                                filled
                                :items="allLanguages"
                                v-model="config.language.available"
                                multiple
                                deletable-chips
                                chips
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click:close="
                                            config.language.available.splice(
                                                config.language.available.findIndex(
                                                    (l) => l === data.item.value
                                                ),
                                                1
                                            )
                                        "
                                    >
                                        <v-avatar left>
                                            <v-img
                                                :src="`https://webimages.we2p.de/static/flags/svg/${data.item.value.slice(
                                                    -2
                                                )}.svg`"
                                            ></v-img>
                                        </v-avatar>
                                        {{ data.item.text }}
                                    </v-chip>
                                </template>
                                <template v-slot:item="data">
                                    <template
                                        v-if="typeof data.item !== 'object'"
                                    >
                                        <v-list-item-content
                                            v-text="data.item"
                                        ></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-avatar tile>
                                            <img
                                                style="object-fit: contain"
                                                :src="`https://webimages.we2p.de/static/flags/svg/${data.item.value.slice(
                                                    -2
                                                )}.svg`"
                                            />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-html="data.item.text"
                                            ></v-list-item-title>
                                            <v-list-item-subtitle
                                                v-html="data.item.value"
                                            ></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <!-- COMET -->
                <v-card flat>
                    <v-card-text>
                        <v-sheet class="pa-5" elevation="0" tile>
                            <p class="headline font-weight-bold">Comet</p>

                            <p class="text-overline mb-0 mt-3">Verbindung</p>
                            <v-text-field
                                style="max-width: 500px"
                                label="Client"
                                filled
                                v-model="config.cometConnection.client"
                            ></v-text-field>

                            <p class="text-overline mb-0 mt-3">Sortierung</p>
                            <v-switch
                                class="mt-0"
                                style="max-width: 500px"
                                label=""
                                filled
                                v-model="config.cometConnection.sorting.enabled"
                            ></v-switch>

                            <p class="text-overline mb-0 mt-3"></p>
                            <v-text-field
                                style="max-width: 500px"
                                type="number"
                                label="Seitenzahl der ersten Seite"
                                filled
                                v-model="
                                    config.cometConnection.firstPageWithEntries
                                "
                            ></v-text-field>

                            <p class="text-overline mb-0 mt-3"></p>
                            <v-select
                                :items="[
                                    { value: 'l', text: 'links' },
                                    { value: 'r', text: 'rechts' },
                                ]"
                                style="max-width: 500px"
                                label="Ausrichtung der ersten Seite"
                                filled
                                v-model="config.cometConnection.sideOfPageOne"
                            ></v-select>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <!-- MAIL -->
                <v-card flat>
                    <v-card-text>
                        <v-sheet class="pa-5" elevation="0" tile>
                            <p class="headline font-weight-bold">
                                Mail Konfiguration
                            </p>

                            <v-switch
                                v-model="config.mail.active"
                                inset
                                :label="
                                    config.mail.active
                                        ? 'Mailversand aktiv'
                                        : 'Mailversand inaktiv -> nur PDF Download'
                                "
                            ></v-switch>

                            <template v-if="config.mail.active">
                                <p class="text-overline my-0">Absender</p>
                                <v-row>
                                    <v-col class="col-12 col-md-4">
                                        <v-text-field
                                            style="max-width: 500px"
                                            label="Name"
                                            filled
                                            v-model="
                                                config.mail.general.senderName
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="col-12 col-md-4">
                                        <v-text-field
                                            style="max-width: 500px"
                                            label="Absender E-Mail (System)"
                                            filled
                                            v-model="
                                                config.mail.general.senderMail
                                            "
                                            persistent-hint
                                            hint="Sicherstellen, dass der Mail-Server entsprechend konfiguriert ist! (IP 193.58.245.3 whitelisten lassen.)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="col-12 col-md-4">
                                        <v-text-field
                                            style="max-width: 500px"
                                            label="Antwort E-Mail"
                                            filled
                                            v-model="
                                                config.mail.general.replyToMail
                                            "
                                            persistent-hint
                                            hint="An diese E-Mail Adresse antwortet der Empfänger."
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <p class="text-overline my-0">Farben</p>
                                <v-row>
                                    <v-col class="col-12 col-md-4">
                                        <v-text-field
                                            style="max-width: 500px"
                                            label="Primär"
                                            filled
                                            :color="config.mail.color.primary"
                                            v-model="config.mail.color.primary"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="col-12 col-md-4">
                                        <v-text-field
                                            style="max-width: 500px"
                                            label="Hintergrund"
                                            filled
                                            :color="
                                                config.mail.color.background
                                            "
                                            v-model="
                                                config.mail.color.background
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <p class="text-overline my-0">Logo</p>
                                <v-row>
                                    <v-col class="col-12 col-md-2">
                                        <v-text-field
                                            style="max-width: 500px"
                                            label="Höhe (z.B. 100px)"
                                            filled
                                            v-model="
                                                config.mail.general.logo.height
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="col-12 col-md-2">
                                        <v-text-field
                                            style="max-width: 500px"
                                            label="Breite"
                                            filled
                                            v-model="
                                                config.mail.general.logo.width
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="col-12 col-md-8">
                                        <v-text-field
                                            label="Quelle (URL)"
                                            filled
                                            v-model="
                                                config.mail.general.logo.src
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <p class="text-overline my-0">Header Bild</p>
                                <v-row>
                                    <v-col class="col-12 col-md-8">
                                        <v-text-field
                                            label="Quelle (URL)"
                                            filled
                                            v-model="
                                                config.mail.general.headerImage
                                                    .src
                                            "
                                            hint="Channel-Thumbnail wird verwendet, falls leer."
                                            persistent-hint
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </template>

                            <p class="text-overline my-0"></p>
                            <v-row>
                                <v-col class="col-12 col-lg-6">
                                    <v-sheet
                                        :class="`grey ${
                                            $vuetify.theme.isDark
                                                ? 'darken-3'
                                                : 'lighten-4'
                                        } pa-5`"
                                    >
                                        <v-row class="mx-0 pt-2 pb-3">
                                            <p class="text-h6 mb-3">Akquise</p>
                                            <v-spacer></v-spacer>
                                            <v-switch
                                                class="ma-0"
                                                dense
                                                v-model="
                                                    config.mail.acquisition
                                                        .active
                                                "
                                                inset
                                            ></v-switch>
                                        </v-row>

                                        <template
                                            v-if="
                                                config.mail.acquisition
                                                    .active &&
                                                config.mail.active
                                            "
                                        >
                                            <v-text-field
                                                label="Betreff"
                                                filled
                                                v-model="
                                                    config.mail.acquisition
                                                        .subject
                                                "
                                            ></v-text-field>
                                            <p class="text-overline mb-3">
                                                Body
                                            </p>
                                            <ckeditor
                                                :editor="editor"
                                                v-model="
                                                    config.mail.acquisition
                                                        .bodyText
                                                "
                                            ></ckeditor>

                                            <p class="text-overline mb-3 mt-5">
                                                Footer
                                            </p>
                                            <ckeditor
                                                :editor="editor"
                                                v-model="
                                                    config.mail.acquisition
                                                        .footerText
                                                "
                                            ></ckeditor>
                                        </template>
                                        <template
                                            v-else-if="config.mail.active"
                                        >
                                            <p>Akquise ist deaktiviert.</p>
                                        </template>
                                    </v-sheet>
                                </v-col>
                                <v-col class="col-12 col-lg-6">
                                    <v-sheet
                                        :class="`grey ${
                                            $vuetify.theme.isDark
                                                ? 'darken-3'
                                                : 'lighten-4'
                                        } pa-5`"
                                    >
                                        <v-row class="mx-0 pt-2 pb-3">
                                            <p class="text-h6 mb-3">Freigabe</p>
                                            <v-spacer></v-spacer>
                                            <v-switch
                                                class="ma-0"
                                                dense
                                                v-model="
                                                    config.mail.confirmation
                                                        .active
                                                "
                                                inset
                                            ></v-switch>
                                        </v-row>

                                        <template
                                            v-if="
                                                config.mail.confirmation
                                                    .active &&
                                                config.mail.active
                                            "
                                        >
                                            <v-text-field
                                                label="Betreff"
                                                filled
                                                v-model="
                                                    config.mail.confirmation
                                                        .subject
                                                "
                                            ></v-text-field>
                                            <p class="text-overline mb-3">
                                                Body
                                            </p>
                                            <ckeditor
                                                :editor="editor"
                                                v-model="
                                                    config.mail.confirmation
                                                        .bodyText
                                                "
                                            ></ckeditor>

                                            <p class="text-overline mb-3 mt-5">
                                                Footer
                                            </p>
                                            <ckeditor
                                                :editor="editor"
                                                v-model="
                                                    config.mail.confirmation
                                                        .footerText
                                                "
                                            ></ckeditor>
                                        </template>
                                        <template
                                            v-else-if="config.mail.active"
                                        >
                                            <p>Freigabe ist deaktiviert.</p>
                                        </template>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <!-- STATUS -->
                <v-card flat>
                    <v-card-text>
                        <v-sheet class="pa-5" elevation="0" tile>
                            <p class="headline font-weight-bold">
                                Status Workflows
                            </p>
                            <p class="text-overline mb-0 mt-3">
                                Akquise Workflow
                            </p>
                            <v-row>
                                <v-col>
                                    <v-switch
                                        :label="
                                            config.status.acquisition.enabled
                                                ? 'aktiviert'
                                                : 'deaktiviert'
                                        "
                                        v-model="
                                            config.status.acquisition.enabled
                                        "
                                        style="max-width: 400px"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                            <p class="text-overline mb-0 mt-3">
                                Freigabe Workflow
                            </p>
                            <v-row>
                                <v-col>
                                    <v-switch
                                        label="Automatische Freigabe"
                                        v-model="
                                            config.status.confirmation.auto
                                        "
                                        style="max-width: 400px"
                                    ></v-switch>
                                    <template
                                        v-if="config.status.confirmation.auto"
                                    >
                                        <v-text-field
                                            dense
                                            class="d-inline-block"
                                            v-model="
                                                config.status.confirmation
                                                    .afterDays
                                            "
                                            type="number"
                                            style="max-width: 100px"
                                            placeholder=""
                                        ></v-text-field>
                                        <span>
                                            Tage bis zur automatischen Freigabe
                                            (0 = sofortige Freigabe)</span
                                        >
                                        <!--v-alert type="warning" dense style="max-width: 680px;">
                                            Momentan nur sofortige Freigabe verfügbar. Freigabe nach X Tagen geplant. <a target="_blank" href="https://we2p.atlassian.net/browse/PP-86">(PP-86)</a>
                                        </v-alert-->
                                    </template>
                                </v-col>
                            </v-row>

                            <p class="text-overline mb-0 mt-3">
                                Nur benutzerdefinierte Status anzeigen
                            </p>
                            <p>
                                Wenn diese Option aktiviert ist, werden auch
                                keine automatischen Status-Übergänge mehr
                                ausgeführt.
                            </p>
                            <v-row>
                                <v-col>
                                    <v-switch
                                        v-model="
                                            config.status.onlyCustomWorkflows
                                        "
                                        style="max-width: 400px"
                                    ></v-switch>
                                </v-col>
                            </v-row>

                            <p class="text-overline mb-0 mt-3">
                                Benutzerdefinierte Status
                            </p>
                            <StatusList v-model="config.status.customStatus" />
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <!-- ACCOUNTING -->
                <v-card flat>
                    <v-card-text>
                        <v-sheet class="pa-5" elevation="0" tile>
                            <p class="headline font-weight-bold">Buchhaltung</p>
                            <v-row>
                                <v-col>
                                    <v-switch
                                        :label="
                                            config.accounting.enabled
                                                ? 'aktiviert'
                                                : 'deaktiviert'
                                        "
                                        v-model="config.accounting.enabled"
                                        style="max-width: 400px"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>

        <v-btn
            :color="saveRequired ? 'warning' : 'success'"
            fixed
            top
            right
            fab
            x-large
            depressed
            @click="saveConfig"
            style="margin-top: 70px"
            :loading="saving"
        >
            <v-icon>save</v-icon>
        </v-btn>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import BlockUnsavedExitMixin from '../../components/mixins/block-unsaved-exit';
import PPSettings from '@/services/pp-settings';
import BasicDataProvider from '@/services/shared/utility/basic-data-provider';
import StatusList from '../../components/settings/StatusList';

export default {
    name: 'Settings',
    components: {
        StatusList,
    },
    mixins: [BlockUnsavedExitMixin],
    data() {
        return {
            deleteEntries: false,
            resetDialog: false,
            channelNameConfirmation: '',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: ['bold', 'italic', '|', 'link'],
            },
            config: this.$settings.getDefaultSettings(),
            initialConfig: {},
            loading: false,
            saving: false,
            reloadForcer: 0,
            channelThumbnail: null,
        };
    },
    computed: {
        channelName: function () {
            return (
                this.$store.getters['channels/getAll']?.[
                    this.$route.params.projectID
                ]?.find((p) => p.id === this.$route.params.channelID)?.labels?.[
                    'de-DE'
                ] || ''
            );
        },
        allLanguages: function () {
            return BasicDataProvider.getAllLanguages();
        },
        saveRequired: function () {
            return !_.isEqual(this.config, this.initialConfig);
        },
        environment: function () {
            return process.env.NODE_ENV;
        },
        thumbnailURL: function () {
            return `https://webimages.we2p.de/static/pp/${this.environment}/channel_${this.$router.currentRoute.params.projectID}_${this.$router.currentRoute.params.channelID}_thumbnail.jpg?${this.reloadForcer}`;
        },
        processedChannels: function () {
            let channels = this.$store.getters['channels/getAll']?.[
                this.$router.currentRoute.params.projectID
            ]?.map((c) => {
                return {
                    ...c,
                    label: c.labels['de-DE'] + ' [' + c.id + ']',
                };
            });
            return channels;
        },
    },
    methods: {
        resetPrintSettings() {
            axios
                .post('/api/v2/channels/reset', {
                    projectChannel: {
                        projectId: this.$router.currentRoute.params.projectID,
                        channelId: this.$router.currentRoute.params.channelID,
                    },
                    options: {
                        deleteEntries: this.deleteEntries,
                    },
                })
                .then((res) => {
                    this.resetDialog = false;
                    this.$store.dispatch('printsettings/fetchSettings', {
                        projectID: this.$router.currentRoute.params.projectID,
                        channelID: this.$router.currentRoute.params.channelID,
                    });
                    this.config = this.$settings.getDefaultSettings();
                    this.fetchConfig();
                })
                .catch((e) => {
                    console.error(e);
                });
        },
        /**
         * send file to backend which uploads it to FTP
         */
        uploadChannelThumbnail() {
            if (!this.channelThumbnail) {
                alert('Kein Bild ausgewählt!');
                return;
            }

            console.log(this.channelThumbnail);

            var bodyFormData = new FormData();

            bodyFormData.append('imageFile', this.channelThumbnail);
            bodyFormData.append(
                'projectId',
                this.$router.currentRoute.params.projectID
            );
            bodyFormData.append(
                'channelId',
                this.$router.currentRoute.params.channelID
            );

            axios
                .post('/api/v2/channels/image', bodyFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    this.reloadForcer++;
                    this.channelThumbnail = null;
                })
                .catch((e) => {
                    this.$notify({
                        group: 'main',
                        duration: -1,
                        type: 'error',
                        title: 'Upload Fehlgeschlagen',
                        text: `Bild konnte nicht hochgeladen werden.`,
                    });
                });
        },
        async saveConfig() {
            this.saving = true;
            let method = this.config._id ? 'put' : 'post';

            if (method === 'post') {
                await axios
                    .post(`/api/printconfigs`, {
                        ...this.config,
                        projectID: this.$router.currentRoute.params.projectID,
                        channelID: this.$router.currentRoute.params.channelID,
                    })
                    .then((res) => {
                        this.fetchConfig();
                        this.$notify({
                            group: 'main',
                            type: 'success',
                            duration: 2000,
                            title: 'Config gespeichert!',
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        // this.saving = false;
                        // saving will be confirmed in fetch config as you shouldn't be able to hit save again if the new config isn't fetched yet
                    });
            } else {
                await axios
                    .put(`/api/printconfigs/${this.config._id}`, this.config)
                    .then((res) => {
                        this.fetchConfig();
                        this.$store.dispatch('printsettings/fetchSettings', {
                            projectID:
                                this.$router.currentRoute.params.projectID,
                            channelID:
                                this.$router.currentRoute.params.channelID,
                        });
                        this.$notify({
                            'animation-name': 'velocity',
                            group: 'main',
                            type: 'success',
                            duration: 2000,
                            title: 'Config gespeichert!',
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        setTimeout(() => {}, 300);
                    });
            }
        },
        async fetchConfig() {
            this.loading = true;
            await axios
                .get(
                    `/api/printconfigs/?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`
                )
                .then((res) => {
                    this.config = _.defaultsDeep(res.data, this.config);
                    this.initialConfig = _.cloneDeep(this.config);
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.saving = false;
                    this.loading = false;
                });
        },
    },
    created() {
        this.fetchConfig();
    },
};
</script>

<style>
.ck.ck-editor {
    color: #000 !important;
}
</style>
